import * as React from "react"

import { Container } from "../../components"
import { Category } from "../../section"
import Seo from "../../components/seo"

const HairRemoval = () => (
  <Container>
    <Seo
      title="メンズ脱毛"
      description="美肌への一番の近道は「脱毛」ということはご存知ですか？ここではヒゲからVIOまで全て脱毛済みの私による脱毛体験談とそのメリット・デメリットを紹介します。"
    />
    <Category
      Title="Hair Removal"
      SubTitle="メンズ脱毛"
      theCategory="hair-removal"
    />
  </Container>
)

export default HairRemoval
